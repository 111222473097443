import React, { useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CgvViewWrapper } from 'ToolboxComponents/webapp/pages/cgv-view/cgv-view.styles';

const CgvView = ( {infos} ) => {
  const theme = useContext(ThemeContext).state;
  const { email } = infos;
  return (
    <CgvViewWrapper theme={theme} className='container'>
    <h2>CGV - Clause de protection des données personnelles</h2>

    <br />

    <p>
      Les données nominatives fournies par l’Utilisateur sur la Plateforme (profil, création de compte administrateur, logs de connexion…) sont nécessaires à la bonne gestion du Service, à la publication de contenus pour et par l’Utilisateur et à l’exploitation des informations sur les utilisateurs finaux. Les données des Utilisateurs ne sont transférées à des tiers que sous réserve d’avoir informé et/ou avoir le obtenu le consentement des Utilisateurs. Ces données sont conservées pour la durée de la relation contractuelle. Le traitement des informations communiquées a fait l'objet d'une déclaration auprès de la CNIL.
    </p>

    <br />

    <p>
      Conformément aux dispositions légales relatives à la protection des données à caractère personnel, l’Utilisateur dispose notamment d'un droit d'accès, de rectification et d'opposition pour motif légitime s'agissant des informations le concernant et du droit d’organiser le sort de ses données post-mortem. Ces droits s’exercent par email à <a href={`mailto:${email}`}>{email}</a> en précisant dans l’objet « MES DONNEES » et en joignant la copie d’un justificatif d’identité. Pour en savoir plus consultez notre <Link to={'/confidentialite'}>Politique de confidentialité</Link>.
    </p>
    </CgvViewWrapper>
  );
}

CgvView.propTypes = {
  infos: PropTypes.object.isRequired
}

export default CgvView;
